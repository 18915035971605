import About from "../pages/AboutPage";
import Cubes from "../pages/CubesPage";
import Home from "../pages/HomePage";
import FAQ from "../pages/FAQPage";

export const directory = [
  { path: "/", page: Home },
  { name: "Cubes", path: "/cubes", page: Cubes },
  { name: "About", path: "/about", page: About },
  { name: "FAQ", path: "/faq", page: FAQ },
  // {
  //   name: "Tickets",
  //   path: "https://buy.stripe.com/00g2b16QV2kH9y0fYZ",
  //   page: "",
  // },
];
