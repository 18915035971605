import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { directory } from "./util/directory";
import NotFoundPage from "./pages/NotFoundPage";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        {directory.map((route) => (
          <Route key={route.name} path={route.path} element={<route.page />} />
        ))}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default App;
