import React from "react";

import MainLayout from "../layouts/MainLayout";

const HomePage: React.FC = () => {
  return (
    <MainLayout>
      <div className="container mx-auto flex flex-col items-start gap-5 p-5">
        {/* <Card>
          <CardBody>
            Looking for Hedron Network? You can find it{" "}
            <a
              href="https://bcp.hedron.network"
              target="_blank"
              rel="noreferrer"
              className="text-rose-800 hover:underline"
            >
              here
            </a>
            .
          </CardBody>
        </Card> */}
        <h1 className="text-4xl font-bold text-center">
          Welcome to Boston Cube Party!
        </h1>
        <p className="text-lg">
          Join us in Boston at the{" "}
          <a
            href="https://www.cambridgecc.org/"
            target="_blank"
            rel="no-referrer noreferrer"
            className="text-rose-800 font-medium hover:underline"
          >
            Cambridge Community Center
          </a>{" "}
          on April 12-13, 2025 to party and Cube like it’s 1773!
        </p>
        <p className="text-lg">
          Boston Cube Party is an annual two-day Magic the Gathering Cube
          tournament featuring over 20 unique brewed Cubes from the local area
          and imported from across the country. Competitors will use{" "}
          <a
            target="_blank"
            rel="no-referrer noreferrer"
            className="text-rose-800 font-medium hover:underline"
            href="https://hedron.network/"
          >
            Hedron Network
          </a>{" "}
          to submit a ranked choice vote for the Cubes they would like to play
          each round, so we can optimize the Cube experience for each player and
          ultimately pair you in pods to face off against competition from
          across the country.
        </p>
        <p className="text-lg">
          We’re so excited to gather with some of our closest friends in Boston
          this April, sip some tea, and sample one of the most refined spreads
          of Cubes that we could assemble.
        </p>
        <p className="text-lg">
          Make sure you don’t miss any tea, follow us on{" "}
          <a
            target="_blank"
            rel="no-referrer noreferrer"
            className="text-rose-800 font-medium hover:underline"
            href="https://x.com/BostonCubeParty"
          >
            Twitter
          </a>
          , and join{" "}
          <a
            target="_blank"
            rel="no-referrer noreferrer"
            className="text-rose-800 font-medium hover:underline"
            href="https://discord.gg/q7kZbeXxAN"
          >
            our Discord
          </a>
          .
        </p>
      </div>
    </MainLayout>
  );
};

export default HomePage;
