import React from "react";

import MainLayout from "../layouts/MainLayout";

const NotFoundPage: React.FC = () => {
  return (
    <MainLayout>
      <div className="container mx-auto flex flex-col md:flex-row justify-start items-center space-y-4 md:space-y-0 gap-4 py-4">
        <h1 className="text-2xl font-medium">404</h1>
        <p className="text-lg font-medium">Page not found</p>
      </div>
    </MainLayout>
  );
};

export default NotFoundPage;
