import React from "react";
import { NavLink } from "react-router-dom";
import { directory } from "../util/directory";
import Copyright from "../components/Copyright";
import logo from "../assets/images/logo.png";

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen bg-slate-100">
      <header className="p-5 border-b border-slate-300">
        <div className="container mx-auto flex flex-col md:flex-row justify-start items-center space-y-4 md:space-y-0 gap-4">
          <div className="flex items-center space-x-4">
            <a href="/">
              <img className="w-96" src={logo} alt="Boston Cube Party" />
            </a>
          </div>
          <div className="flex flex-col flex-wrap items-start gap-5">
            {directory
              .filter((tab) => tab.name)
              .map((tab) => (
                <NavLink
                  key={tab.name}
                  to={tab.path}
                  className={({ isActive }) =>
                    isActive
                      ? "text-rose-950 border-b-2 border-rose-900 text-xl font-medium"
                      : "text-black text-xl hover:text-rose-800 transition duration-300 font-medium"
                  }
                >
                  {tab.name}
                </NavLink>
              ))}
          </div>
        </div>
      </header>
      <main className="flex-grow bg-slate-200 flex flex-col items-stretch">
        {children}
      </main>
      <footer className="p-5 bg-slate-800 text-white text-sm">
        <div className="mx-auto text-center">
          <Copyright />
        </div>
      </footer>
    </div>
  );
};

export default MainLayout;
