import React from "react";

import MainLayout from "../layouts/MainLayout";

const cubes2025 = [
  {
    name: "The Creatureless Cube",
    description:
      "This cube gives beloved (but powercrept or niche) cards a chance to shine once again with heavily synergistic, play-to-the-board gameplay.",
    id: "63e410ffb2e2394dc650e909",
    img: "https://cards.scryfall.io/art_crop/front/4/c/4c016fba-312d-43fc-92b6-212147b2f578.jpg?1630246886",
  },
  {
    name: "Tiny Leaders",
    description:
      "This cube blends the synergistic reliability of a recastable card in the command zone with interactive small game cards.",
    id: "5d39e7f38472c42aab0b73d6",
    img: "https://cards.scryfall.io/art_crop/front/c/8/c8b432a7-53da-4480-b571-e6feb1364a3a.jpg?1562793427",
  },
  {
    name: "Loam Cube",
    description:
      " Loam Cube is a desert cube where each player starts with a Life from the Loam in their graveyard. Come dredge, retrace, delve, cast some Goyfs, then win by drawing from an empty library.",
    id: "517cd182-38af-4a5f-a12e-50ced313078a",
    img: "https://cards.scryfall.io/art_crop/front/6/2/6200afaf-2720-488a-b760-e4d17d8aea79.jpg?1702416097",
  },
];

const cubes2024 = [
  {
    name: "The Creatureless Cube",
    description:
      "This cube gives beloved (but powercrept or niche) cards a chance to shine once again with heavily synergistic, play-to-the-board gameplay.",
    id: "63e410ffb2e2394dc650e909",
    img: "https://cards.scryfall.io/art_crop/front/4/c/4c016fba-312d-43fc-92b6-212147b2f578.jpg?1630246886",
  },
  {
    name: "Peebs Cube",
    description:
      "Peebs Cube is a legacy cube with low curves and lots of interaction. But don't let your guard down, there's some spice, especially in artifact/graveyard decks.",
    id: "63cddcb1a3b5060f686c7d19",
    img: "https://cards.scryfall.io/art_crop/front/6/6/66392169-5c6f-46bf-b0df-5670e40aecd9.jpg?1562913900",
  },
  {
    name: "Tiny Leaders",
    description:
      "This cube blends the synergistic reliability of a recastable card in the command zone with interactive small game cards.",
    id: "5d39e7f38472c42aab0b73d6",
    img: "https://cards.scryfall.io/art_crop/front/c/8/c8b432a7-53da-4480-b571-e6feb1364a3a.jpg?1562793427",
  },
  {
    name: "Invading DOM",
    description:
      "Invading Dom is a love letter to Dominaria. Each card is hand picked from one of our visits to the plane.",
    id: "610ad33d1956c010459983a4",
    img: "https://cards.scryfall.io/art_crop/front/3/5/356744f3-e444-4f4e-bf00-80bb6b2ef76f.jpg?1562905776",
  },
  {
    name: "SHADOW WIZARD MONEY GANG",
    description:
      "Our local group brings us a color-restricted cube with only swamps in the basic land box, and plenty of strong mono B and Bx cards to draft. Achieve greatness at all costs!",
    id: "634ad80f6a41630f683ef91b",
    img: "https://cards.scryfall.io/art_crop/front/5/a/5a2747ab-00c8-4f59-b9a6-54ff4e99f6c8.jpg?1562719284",
  },
  {
    name: "Loam Cube",
    description:
      " Loam Cube is a desert cube where each player starts with a Life from the Loam in their graveyard. Come dredge, retrace, delve, cast some Goyfs, then win by drawing from an empty library.",
    id: "517cd182-38af-4a5f-a12e-50ced313078a",
    img: "https://cards.scryfall.io/art_crop/front/6/2/6200afaf-2720-488a-b760-e4d17d8aea79.jpg?1702416097",
  },
  {
    name: "Sci-Fi Cube",
    description:
      "It is the distant future: the year 2000. The Forests are dead. Finally, robots rule the world. Sci-Fi Cube is an artifact-centric cube dripping with theme and sweet synergies!",
    id: "5ecd517c4022a8067a285177",
    img: "https://cards.scryfall.io/art_crop/front/c/6/c60174d6-1f9d-4870-b3db-34d6fcb3f6ab.jpg?1564535860",
  },
  {
    name: "Devoid Cube",
    description:
      "Devoid Cube is a unique semi-desert environment where the basic land station contains only Wastes and all spells are colorless!",
    id: "5dc9a64553f3101ce8e1d08c",
    img: "https://cards.scryfall.io/art_crop/front/6/0/60682c00-c661-4a9d-8326-f3f014a04e3e.jpg?1562914528",
  },
  {
    name: "Budget Synergy Cube",
    description:
      "This cube takes fan-favorite classic archetypes like izzet spells, rakdos sacrifice, and golgari self-mill and cranks the synergy up to 11!",
    id: "623220f59876560fe936f3b9",
    img: "https://cards.scryfall.io/art_crop/front/2/0/2047bfa4-3f4d-47bd-9484-545686f15b75.jpg?1608908659",
  },
  {
    name: "da cube z0ne",
    description:
      "Da cube z0ne is our group's most played cube. It's a highly refined blue-skewed vintage cube with lots of decision points and tight gameplay. ",
    id: "30cc9421-f719-41f3-a217-39f259c05e92",
    img: "https://cards.scryfall.io/art_crop/front/f/3/f3edb27c-4faa-464a-9bb1-fbe17013c022.jpg?1688130725",
  },
  {
    name: "540 DEREK'S CUBE",
    description: `"I built this cube after years of building cubes that attempt to control variance. I'm grateful to Matt Grenier for our friendship and his inspired view on games. A fluid and improvisational spectacle awaits you." - Derek`,
    id: "f783b2ba-03a3-4954-aef0-44a3b62a5104",
    img: "https://cards.scryfall.io/art_crop/front/f/a/fab4cd7e-b56f-4408-a0e9-c07e040cc38f.jpg?1562948100",
  },
  {
    name: "XLN-M20 Standard Cube",
    description:
      "Standard is great, but do you remember the last time it was this good? Relive that glorious epoch or experience it for the first time! Nexus of Fate not included.",
    id: "61a116c5db49f110309fc0d3",
    img: "https://cards.scryfall.io/art_crop/front/7/8/787de9ce-02c5-4a17-a88b-d38e83dbeb0b.jpg?1572893092",
  },
  {
    name: "The Pauper Cube",
    description:
      "This cube has almost 2,000 followers on CubeCobra and is over 15 years old! Whether you’ve been curious about pauper cube or you’re already a huge pauper cuber, you won’t want to miss this one!",
    id: "5d617ac6c2a85f3b75fe95a4",
    img: "https://cards.scryfall.io/art_crop/front/4/f/4f3deefe-28bc-4e45-a0a0-ab03167e2e81.jpg?1561942156",
  },
  {
    name: "Bodleian Cube",
    description:
      " Bodleian Cube is a high synergy, eternal cube which rewards creativity in drafting and deckbuilding. Iconic cards and strategies that build an engine coexist in this cube.",
    id: "61454789685c83106293be3c",
    img: "https://cards.scryfall.io/art_crop/front/3/0/3009ba46-c9f8-46dc-8ffc-2aa4cef7b17c.jpg?1623779988",
  },
  {
    name: "The Buildaround Cube",
    description:
      "This cube has incredibly deep drafting decisions. Each pack is seeded with a buildaround card and each player starts the draft with a cogwork librarian.",
    id: "638032857504190f6ada9d67",
    img: "https://cards.scryfall.io/art_crop/front/4/5/453de664-0f09-4772-a626-51d58d1173f3.jpg?1562864968",
  },
  {
    name: "The Altered Cube",
    description:
      "Only available for our top 8, the stunning Altered Cube is a sight to behold. The vast majority of the cards in this cube have been altered by hand by talented artists from all over the world.",
    id: "63f8e97f81e88359951591ee",
    img: "https://cards.scryfall.io/art_crop/front/0/f/0fee7b1f-e300-411a-957d-5253300c36cf.jpg?1562487458",
  },
];

const Cubes: React.FC = () => {
  return (
    <MainLayout>
      <div className="container mx-auto flex flex-col items-start gap-5 p-5">
        <h1 className="text-4xl font-bold text-center">
          The Cubes of Boston Cube Party 2025
        </h1>
        <p className="text-xl font-medium">More cubes to be announced soon!</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
          {cubes2025.map((cube) => (
            <div
              key={cube.id}
              className="bg-white rounded-lg shadow-md overflow-hidden"
            >
              <a
                href={`https://cubecobra.com/cube/overview/${cube.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={cube.img}
                  alt={cube.name}
                  className="w-full h-48 object-cover"
                />
              </a>
              <div className="p-5">
                <a
                  href={`https://cubecobra.com/cube/overview/${cube.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <h2 className="text-xl font-bold text-rose-800 hover:underline">
                    {cube.name}
                  </h2>
                </a>
                <p className="text-lg">{cube.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container mx-auto flex flex-col items-start gap-5 p-5">
        <h1 className="text-4xl font-bold text-center">2024 Cubes</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
          {cubes2024.map((cube) => (
            <div
              key={cube.id}
              className="bg-white rounded-lg shadow-md overflow-hidden"
            >
              <a
                href={`https://cubecobra.com/cube/overview/${cube.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={cube.img}
                  alt={cube.name}
                  className="w-full h-48 object-cover"
                />
              </a>
              <div className="p-5">
                <a
                  href={`https://cubecobra.com/cube/overview/${cube.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <h2 className="text-xl font-bold text-rose-800 hover:underline">
                    {cube.name}
                  </h2>
                </a>
                <p className="text-lg">{cube.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </MainLayout>
  );
};

export default Cubes;
